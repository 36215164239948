<template>
  <div class="yms_bg">
    <div class="yms_top" style="background-image: url(img/ymScore/yms_top.png);"></div>
    <div class="yms_box">
      <div class="yms_list">


        <div class="yms_nav display_flex justify-content_flex-justify align-items_center">
          <div class="yms-title" :style="{backgroundImage: `url(img/ymScore/${rankCur == index ? item.list_type+'_cur':item.list_type}.png)`}" v-for="(item,index) in ranklist" :key="index" @click="rankchange(index,item.list_type)"></div>
        </div>

        <div v-if="nodata" class="roomnoData">
          <img :src="'img/noData.png'" alt="">
        </div>
        
        <div v-if="listItems.length > 0">
            <div class="rom-item display_flex justify-content_flex-justify align-items_center" v-for="(item,index) in listItems" :key="index">
              <div class="display_flex align-items_center" @click="checkUser(item.id)">
                <img class="yms-order" :src="`img/ymScore/${index+1}.png`" alt="">
                <img class="yms-img" :src="item.pic" alt="">
                <div class="yms-name">{{item.nickname}}</div>
              </div>
              <div class="yms-weal display_flex align-items_center">
                <span>{{item.total_score}}</span>
                <p>得分</p>
              </div>
              
            </div>
        </div>


      </div>
    </div>

  </div>
</template>

<script>
import "@/assets/css/rank.css"
import { playGuessDisplay, playGuessList } from "@/api/ranking";
export default {
  name: 'ymScore',
  data () {
    return {
      rankCur:0,
      ranklist:[],
      sort_date:'',
      listItems:[],
      page:1,
      nodata:false,
    }
  },
  created() {
    let access_token = this.$route.query.access_token
    localStorage.setItem('access_token', access_token)
    this.getData()
  },
  methods: {
    //切换
    rankchange(e,type){
      this.rankCur=e
      this.sort_date = type
      this.page = 1
      this.getList()
    },
    getData() {
        playGuessDisplay().then(response => {
          this.ranklist = response.data
          this.sort_date = response.data[0].list_type
          this.getList()
        },
        error => {
          this.$toast(error)
        }).catch((error) => {
          this.$toast(error)
        })
    },
    getList() {
        let param = {
            page:1,
            type:this.sort_date
        }
        playGuessList(param).then(response => {
          this.listItems = response.data
          if(response.data.length == 0){
            this.nodata = true
          }else{
            this.nodata = false
          }
        },
        error => {
          this.$toast(error)
        }).catch((error) => {
          this.$toast(error)
        })
    },
    closePage(){
      let params = '调用'
      this.$bridge.callhandler('closePage', params, (data) => {
      // 处理返回数据
      })
    },
    checkUser(id){
      let param = id.toString()
      this.$bridge.callhandler('checkUser', param, (data) => {
      // 处理返回数据
      })
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->